.loading {
	.full {
		background: $orange;
		color: white;
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		text-align: center;
	}
	.wrapper {
		position: absolute;
		top:50%;
		left:50%;
		transform:translate(-50%, -50%);
		max-width:$animationWidth;
	}
	svg {
		display: block;
		margin:0 auto;
		height:auto;
	}
}

#animation-compass {
	width:100%;
	margin-bottom:60px;
	#triangle {
		animation:arrowSpin 2.25s infinite alternate ease forwards;
		transform-origin:center center;
	}
	#background {
		animation:backgroundSpin 2.25s infinite alternate ease forwards;
		transform-origin:center center;
	}
}

#animation-text {
	width: 60%;
}

@keyframes arrowSpin {
	0% {
		transform:rotate(-22.5deg);
	}
	5% {
		transform:rotate(-22.5deg);
	}
	95% {
		transform:rotate(22.5deg);
	}
	100% {
		transform:rotate(22.5deg);
	}
}

@keyframes backgroundSpin {
	0% {
		transform:rotate(45deg);
	}
	5% {
		transform:rotate(45deg);
	}
	95% {
		transform:rotate(-45deg);
	}
	100% {
		transform:rotate(-45deg);
	}
}