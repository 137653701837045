.avatars {
	background: $lightBlue;
	h1 {
		font-size:48px;
		text-transform: uppercase;
		color:$blue;
		text-align: center;
		margin:1em 0;
	}
	ul {
		list-style:none;
		text-align: center;
	}
	li {
		padding:10px;
		display: inline-block;
		background:white;
		border-radius:50%;
		position: relative;
		cursor: pointer;
		margin:25px;
		&:before, &:after {
			content:"";
			border-radius:50%;
			position: absolute;
			left:50%;
			top:50%;
			transform:translate(-50%, -50%);
			border-radius:50%;
		}
		&:before {
			z-index:2;
			height:85%;
			width:85%;
			background:white;
		}
		&:after {
			background: $lightBlue;
			border:8px solid $blue;
			height:100%;
			width:100%;
			z-index:1;
		}
		img {
			width:150px;
			z-index:3;
			position: relative;
		}
	}

	section {
		@extend %overlay;
		background:rgba(255,255,255,0.7);
		text-align: center;
		&:after { //for my next trick, we'll use this to force vertical centering! aah, the good ol' days...
			width:0px;
			height:100%;
			display: inline-block;
			vertical-align: middle;
			content:"";
		}
		button {
			@extend %button;
			width:180px;
			display: inline-block;
			vertical-align: middle;
			&#go {
				background:$gold;
			}
		}
		input {
			border-radius:40px;
			border:10px solid $blue;
			font-size:36px;
			padding:20px 10px;
			text-align: center;
			outline:none;
		}
		div {
			display: inline-block;
			vertical-align: middle;
			padding:40px;
			span {
				position: relative;
				float: left; //forces block context without auto-expanding width
				&:before, &:after {
					position: absolute;
					top:50%;
					left:50%;
					transform:translate(-50%, -50%);
					border-radius:50%;
					content:"";
				}
				&:before {
					z-index:2;
					background:white;
					width:100%;
					height:100%;
					border:20px solid $blue;
				}
			}
			img {
				width:500px;
				position: relative;
				z-index:3;
			}
		}
	}
}