.banner {
	margin-bottom:$verticalSpacing;
	h1 {
		margin-bottom:$verticalSpacing;
	}
	img {
		width:100%;
	}
	h2 {
		text-transform: uppercase;
		color:$blue;
		font-size:36px;
		text-align: center;
		font-weight: bold;
		margin-bottom:1em;
	}
	ul {
		text-align: center;
		font-size:24px;
	}
	li {
		display: inline-block;
		text-transform: uppercase;
		font-weight: bold;
		position: relative;
		& + li {
			margin-left:40px;
		}
		&.active {
			&:after {
				content:"";
				position: absolute;
				top:-4px;
				left:-4px;
				width:calc(100% + 8px);
				height:calc(100% + 8px);
				border-radius:22px; //magic number :(
				background:$blue;
			}
		}
	}
	a {
		padding:10px 60px;
		color:white;
		display: block;
		position: relative;
		z-index:2;
		background:$yellow;
		border:4px solid $lightBlue;
		border-radius:20px;
	}
}

.browse {
	background: $lightBlue;
}

.icon-grid {
	text-align: center;
	li {
		width:25%;
		display: inline-block;
		vertical-align: top;
		padding:$verticalSpacing;
		font-size:14px;
		text-transform: uppercase;
	}
	a {
		&:hover {
			img {
				filter:brightness(110%);
			}
		}
	}
	img {
		width:100%;
		margin-bottom:$verticalSpacing/2;
		transition:filter .3s;
	}
}

.video-mask {
	position: relative;
	background: black;
	img {
		position: relative;
		z-index: 2;
		pointer-events: none;
		width:100%;
	}
	.video-container {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		&.header {
			top:23.9973262032%;
			right:6.73828125%;
			bottom:10.8957219251%;
			left:6.494140625%;
		}
		&.footer {
			top:13.2375189107%;
			right:6.591796875%;
			bottom:13.0862329803%;
			left:6.640625%;
		}
	}
}


//Positions the player buttons so they are not obscured by the overlay graphics
.video-container {
	&.crown-hill-cemetery {
		&.header {
			top:22.5%;
			.jw-button-container {
				padding-left:11.1361079865% !important;
			}
		}
		&.footer {
			.jw-button-container {
				padding-right:23.9788199697% !important;
			}
		}
	}
	&.walker-theatre {
		&.header {
			top:19%;
			right:6%;
			bottom:13.3%;
			.jw-button-container {
				padding-right:7% !important;
			}
		}
		&.footer {
			top:13%;
			right:6%;
			bottom:15.5%;
			left:6%;
		}
	}
	&.turkey-run-state-park {
		&.header {
			top:18%;
			right:6.2%;
			bottom:10%;
			.jw-button-container {
				padding-right:15.3020134228% !important;
			}
		}
		&.footer {
			bottom:14%;
			.jw-button-container {
				padding-left:12.2794636556% !important;
			}
		}
	}
	&.new-harmony {
		&.header {
			top:23%;
			right:6.3%;
			.jw-button-container {
				padding-left:18.1660899654% !important;
			}
		}
		&.footer {
			top:11%;
			left:6%;
			.jw-button-container {
				padding-left: 40%;
				padding-right:10.2564102564% !important;
			}
		}
	}
	&.limberlost {
		&.header {
			top:22.5%;
			.jw-button-container {
				padding-left: 11% !important;
			}
		}
		&.footer {
			top:16%;
			.jw-button-container {
				padding-left: 6.8078668684% !important;
			}
		}
	}
	&.lincoln-state-park {
		&.header {
			top:21.5%;
			right:6.4%;
		}
		&.footer {
			top:11%;
			left:6.3%;
			.jw-button-container {
				padding-left: 12%;
				padding-right:29.5489891135% !important;
			}
		}
	}
	&.wind-farms {
		&.header {
			top:21%;
			bottom:15%;
			left:6%;
			.jw-button-container {
				padding-left:10% !important;
			}
		}
		&.footer {
			left:6%;
			bottom:15%;
			.jw-button-container {
				padding-right:13.7315748642% !important;
			}
		}
	}
	&.capitols {
		&.header {
			top:20%;
		}
		&.footer {
			.jw-button-container {
				padding-right:10% !important;
			}
		}
	}
	&.levi-coffin-house {
		&.header {
			top:22%;
			right:6%;
		}
		&.footer {
			top:14.2%;
			left:6%;
		}
	}
}