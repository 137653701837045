* {
	margin:0;
	padding:0;
	box-sizing:border-box;
	font-family:Quicksand, sans-serif;
	letter-spacing:.05em;
	@extend %noselect;
}

html {
	height:100%;
	min-height:100%;
	background: $lightBlue;
}

body {
	min-height:100%;
	transition:padding-top .3s;
	background: $lightBlue;
	&.withMenu {
		padding-top:$appMenuHeight;
	}
}

a {
	text-decoration: none;
	color: black;
}

img {
	max-width:100%;
	display: block;
}

ng-view {
	display: block;
	min-height:calc(100vh - 100px);
	overflow: hidden; //contain those rogue margins on first & last children
}

%button {
	background:$red;
	color:white;
	border:none;
	border-radius:20px;
	text-transform: uppercase;
	font-size:24px;
	font-weight: bold;
	outline:none;
	height:65px;
	width:120px;
	cursor: pointer;
}

