.main-menu {
	background:white;
	padding:$gridGutter;
	@extend %clearfix;
	a {
		float: right;
		width: calc(100% - #{$mainMenuLeftPercentage});
		&:hover {
			img {
				filter:brightness(110%);
			}
		}
	}
	.inactive {
		position: relative;
		img {
			opacity:.3;
		}
		&:after {
			content: "Coming Soon";
			position: absolute;
			bottom: 0px;
			left: 8px;
			text-transform: uppercase;
			color: white;
			font-weight: bold;
			background: $blue;
			padding: 5px 10px;
			border-top-right-radius: 4px;
		}
	}
	a:first-child {
		float: left;
		width: $mainMenuLeftPercentage;
		padding:0 $gridGutter/2 0 0;
	}
	a:not(:first-child){
		padding-left: $gridGutter/2;
	}
	a:nth-child(2){
		//padding-bottom:$gridGutter*2/3;
		margin-bottom:$gridGutter;
	}
	a:nth-child(3){
		//padding-top:$gridGutter*2/3/2;
		//padding-bottom:$gridGutter*2/3/2;
		margin-bottom:$gridGutter;
	}
	a:nth-child(4){
		//padding-top:$gridGutter*2/3;
	}
	img {
		width:100%;
		transition:filter .3s;
	}
}
