.chart {
  display: block;
  margin: auto;
}
.chart rect {
  fill: steelblue;
}
.chart text {
  fill: white;
  font-size: 40pt;
  font-weight: bold;
}
.axis text {
  fill: #045BA6;
}
.x.axis text {
  font-size: 8pt;
  transform: rotate(45deg);
  text-anchor: start;
}
.y.axis text {
  font-size: 20pt;

}
.axis path,
.axis line {
  fill: none;
  stroke: #045BA6;
  opacity: 0.5;
  shape-rendering: crispEdges;
}
