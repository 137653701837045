appmenu {
	background:$blue url(../images/logo-compass.svg) center center no-repeat;
	background-size:auto 80%;
	position: fixed;
	top:0;
	left:0;
	width:100%;
	height:$appMenuHeight;
	z-index:10000;

	header {
		height:$appMenuHeight;
	}

	button {
		@extend %button;
		position: absolute;
		top:50%;
		transform:translateY(-50%);
		left:$horizontalGutter;
	}

	#avatar {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: $horizontalGutter + $appMenuHeight*.7*1.15 - $appMenuHeight*.7; //compensate for the extra border
		height: 70%;
		&:after {
			position: absolute;
			top: 50%;
			left: 50%;
			height: 115%;
			width: 115%;
			background: $blue;
			border: 1px dashed white;
			border-radius: 50%;
			content: "";
			transform: translate(-50%, -50%);
		}
		img {
			height:100%;
			background: white;
			border-radius: 50%;
			display: block;
			position: relative;
			z-index:1;
		}
	}
}