.grid {
	background:white;
}

thumbnailgrid {
	display: block;
	padding:$gridGutter/2; //need to put this here instead of on the <ol> to prevent Masonry issues
	&.empty {
		padding:0;
	}

	ol {
		list-style:none;
		counter-reset: order;
		@extend %clearfix;
		&.numbered {
			li:before {
				counter-increment: order;
				content:counter(order);
				font-size:60px;
				font-weight: bold;
				color:white;
				position: absolute;
				top: $gridGutter*2;
				left: $gridGutter*2;
				text-shadow:0 0 3px rgba(0,0,0,0.2);
				z-index:1;
			}
		}
	}
	li {
		width:50%;
		float: left;
		position: relative;
		padding:$gridGutter/2;
		min-height:200px;
		&.photo {
			.icon {
				top:75%;
			}
		}
	}
	h2 {
		background:$blue;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space:nowrap;
		text-transform: uppercase;
		color:white;
		font-weight: bold;
		text-align: center;
		padding:10px 5px;
	}
	.icon {
		position: absolute;
		top:50%;
		left:50%;
		transform:translate(-50%, -50%);
		width:175px;
	}
	img {
		transition:filter .3s;
		margin-right:auto;
		margin-left:auto;
	}
	a {
		&:hover {
			img:not(.icon) {
				filter:brightness(110%);
			}
		}
	}
}