.travelbook {
	height: 100%;
	background: #9bbddb url(../images/travelbook-background.jpg) center center fixed no-repeat / cover;
	.book {
		background: white;
		width:90%;
		margin:5% auto;
	}
	header {
		display: inline-block;
		color:white;
		position: relative;
		img {
			width: 125px;
			height: 125px;
			transform:translate(-25%, -25%);
			border:10px solid $blue;
			border-radius:50%;
			position: absolute;
			top:0;
			left:0;
			background:white;
		}
		h1 {
			margin-left:30px;
			text-transform: uppercase;
			font-size:2.5vw;
			background:$blue;
			padding:15px 15px 15px 70px;
		}
	}
	ul {
		list-style: none;
		@extend %clearfix;
		padding:40px;
	}
	li {
		width: 25%;
		float: left;
		position: relative;
		padding:25px;
		@media screen and (min-width:1200px){
			width:16.66%;
		}
	}
}
