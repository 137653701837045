$blue:#045BA6;
$red:#CD163F;
$yellow:#FFC950;
$orange:#E9662B;
$green:#8FAC67;

$lightBlue:#D9F1FA;
$lightGreen:#22592F;

$gold:#FAA819;

$horizontalGutter:30px;
$gridGutter:16px;
$appMenuHeight:100px;
$verticalSpacing:40px;
$mainMenuLeftPercentage: 44.7497%;
$animationWidth: 400px;




%overlay {
	position: fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:1000000000000;
}

%clearfix {
	&:after {
		content:"";
		display:table;
		clear:both;
	}
}

%noselect {
	user-select:none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}


@font-face {
	font-family: 'Quicksand';
	src: url('../fonts/quicksand/quicksand-bold-webfont.eot');
	src: url('../fonts/quicksand/quicksand-bold-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/quicksand/quicksand-bold-webfont.woff2') format('woff2'),
	url('../fonts/quicksand/quicksand-bold-webfont.woff') format('woff'),
	url('../fonts/quicksand/quicksand-bold-webfont.ttf') format('truetype'),
	url('../fonts/quicksand/quicksand-bold-webfont.svg#quicksandbold') format('svg');
	font-weight: bold;
	font-style: normal;

}

@font-face {
	font-family: 'Quicksand';
	src: url('../fonts/quicksand/quicksand-regular-webfont.eot');
	src: url('../fonts/quicksand/quicksand-regular-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/quicksand/quicksand-regular-webfont.woff2') format('woff2'),
	url('../fonts/quicksand/quicksand-regular-webfont.woff') format('woff'),
	url('../fonts/quicksand/quicksand-regular-webfont.ttf') format('truetype'),
	url('../fonts/quicksand/quicksand-regular-webfont.svg#quicksandregular') format('svg');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'Gandhi';
	src: url('../fonts/gandhisans/GandhiSans-Regular-webfont.eot');
	src: url('../fonts/gandhisans/GandhiSans-Regular-webfont.eot?iefix') format('eot'),
	url('../fonts/gandhisans/GandhiSans-Regular-webfont.woff') format('woff'),
	url('../fonts/gandhisans/GandhiSans-Regular-webfont.ttf') format('truetype'),
	url('../fonts/gandhisans/GandhiSans-Regular-webfont.svg#webfont') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Gandhi';
	src: url('../fonts/gandhisans/GandhiSans-Bold-webfont.eot');
	src: url('../fonts/gandhisans/GandhiSans-Bold-webfont.eot?iefix') format('eot'),
	url('../fonts/gandhisans/GandhiSans-Bold-webfont.woff') format('woff'),
	url('../fonts/gandhisans/GandhiSans-Bold-webfont.ttf') format('truetype'),
	url('../fonts/gandhisans/GandhiSans-Bold-webfont.svg#webfont') format('svg');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'Gandhi';
	src: url('../fonts/gandhisans/GandhiSans-Italic-webfont.eot');
	src: url('../fonts/gandhisans/GandhiSans-Italic-webfont.eot?iefix') format('eot'),
	url('../fonts/gandhisans/GandhiSans-Italic-webfont.woff') format('woff'),
	url('../fonts/gandhisans/GandhiSans-Italic-webfont.ttf') format('truetype'),
	url('../fonts/gandhisans/GandhiSans-Italic-webfont.svg#webfont') format('svg');
	font-weight: normal;
	font-style: italic;
}
