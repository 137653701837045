ack%streamHeading {
	text-align: center;
	margin-bottom:1em;
	line-height:1.3;
}

.stream {
	height:100%;
	width:100%;
	position: absolute;
	top:0;
	left:0;

	.event {
		width: 80%;
		position: absolute;
		top:50%;
		left:50%;
		transform:translate(-50%, -50%);
		text-transform: uppercase;
		color:$blue;
		font-weight: bold;
		font-size:3.75vw;
		letter-spacing:.05em;
		line-height:1.3;
		max-height:100%;
		overflow: auto;
		padding:40px 0; //space at the top and bottom just in case
	}

	.clear {
		width:100%;
		height:100%;
		background:url('../images/holding-screen.png') center center no-repeat;
		background-size:cover;

		header {
			height:$appMenuHeight;
		}

		button {
			@extend %button;
			top:50%;
			transform:translateY(-50%);
			margin-left:$horizontalGutter;
		}
	}

	.question {
		$dotSize: 36px; //must be even number because not all devices support fractional pixels (retina)
		$borderWidth: $dotSize/9;
		width: 100%;
		height:100%;
		padding:0;
		overflow: visible;
		.prompt {
			width:80%;
			margin:0 auto;
			padding:40px 0;
			position: absolute;
			top:50%;
			left:50%;
			transform:translate(-50%, -50%);
			overflow: auto;
		}
		p {
			@extend %streamHeading;
		}
		ol {
			list-style:none;
			font-size:3vw;
		}
		li {
			position: relative;
			min-height:$dotSize;
			margin-bottom:1em;
			padding-left:$dotSize + $dotSize * .65;
			&:last-child {
				margin-bottom:0;
			}
		}
		label {
			cursor: pointer;
		}
		input {
			position: absolute;
			left:0;
			top:0;
			visibility: hidden;
			z-index:-1;
			&:checked + span {
				&:before {
					background:$blue;
				}
				&:after {
					display: block;
				}
			}
		}
		span {
			&:before {
				content:"";
				background:white;
				border:$borderWidth solid $blue;
				border-radius: 50%;
				height:$dotSize;
				width: $dotSize;
				position: absolute;
				top:50%;
				transform:translateY(-50%);
				left:0;
			}
			&:after {
				border:$borderWidth/2 solid white;
				position: absolute;
				left:($dotSize - ($dotSize - $borderWidth)) / 2 + $borderWidth/2; //8 & 2px for border width
				top:50%;
				transform:translateY(-50%);
				width:$dotSize - $dotSize/10;
				height:$dotSize - $dotSize/10;
				border-radius:50%;
				content:"";
				display: none;
			}
		}
		img {
			float: right;
			width: 40%;
			//using a sibling selector allows the answers to go full width when no image is present
			& + ol {
				width: 60%;
				padding-right:20px;
				float: left;
				margin-top:1em;
			}
		}

		.result {
			position: absolute;
			width: 100%;
			height:100%;
			left:0;
			top:0;
			background:rgba(255,255,255,0.7);
			font-size:6vw;
		}

		.feedback {
			position: absolute;
			width:40%;
			top:50%;
			left:50%;
			transform:translate(-50%, -50%);
			text-align: center;
			border-radius: 50%;
			background-color: $yellow;
			color:white;
			&:after {
				width: 105%;
				height:105%;
				border-radius: 50%;
				border:12px solid $orange;
				content: "";
				position: absolute;
				top:50%;
				left:50%;
				transform:translate(-50%, -50%);
			}
			&.survey {
				background: $green;
				&:after {
					border-color: $lightGreen;
				}
			}
			&.incorrect {
				background: $orange;
				&:after {
					border-color: $yellow;
				}
			}
			div {
				padding-top:100%;
				display: block;
				padding-top:100%;
				height:0;
			}
			output {
				position: absolute;
				top:50%;
				left:50%;
				transform:translate(-50%, -50%);
			}
		}
	}

	.text {
		h1 {
			@extend %streamHeading;
			margin-bottom:.5em;
		}
		img {
			max-height:500px;
			margin:0 auto;
		}
	}

	.image {
		img {
			max-height:500px;
			margin:0 auto;
			margin-bottom:1em;
		}
		figcaption {
			@extend %streamHeading;
			font-size:2.5vw;
			text-align: center;
		}
		&.full {
			width: 100%;
			padding:0;
			img {
				max-height:none;
				margin:0;
			}
		}
	}

	.end {
		width:100%;
		height:100%;
		max-height:none;
		background:#77CEE0;
		text-align: center;
		color:white;
		padding:0;
		&:before, &:after {
			position: fixed;
			bottom:0;
			width:30%;
			content:"";
			background-position:left bottom;
			background-size:100% auto;
			background-repeat: no-repeat;
			height:100%;
			z-index:-1;
			display: block;
		}
		&:before {
			left:0;
			background-image:url(../images/end-left.svg);
		}
		&:after {
			right:0;
			background-image:url(../images/end-right.svg);
		}
		header {
			background:$blue;
			padding:15px 10px;
			margin-bottom:50px;
			img {
				height:120px;
				width:auto;
				display: block;
				margin:0 auto;
			}
		}
		h1 {
			padding: 0 10px;
			margin-bottom:20px;
			img {
				height: 120px;
				width: auto;
				display: block;
				margin: 0 auto;
			}
		}
		h2 {
			margin-bottom:1em;
			color:$blue;
			font-size:3vw;
		}
		div {
			font-size:2vw;
		}
		output {
			color:$blue;
			background:white;
			border-radius:20px;
			padding:10px 30px;
			display: block;
			margin:.5em 0;
			font-size:4vw;
			display: inline-block;
		}
		button {
			@extend %button;
			background-color:$yellow;
			width:300px;
			margin-top:2em;
		}
	}

	.graph {
		h1 {
			text-align: center;
			margin-bottom:.5em;
		}
		p {
			font-size:2vw;
			margin-bottom:2em;
			margin-top:1em;
		}
		ol {
			font-size:2vw;
			li {
				margin-bottom:1em;
			}
		}
		.swatch {
			width:30px;
			height:30px;
			display: inline-block;
			vertical-align: top;
			margin-right:.5ex;
			&.key {
				padding-right:60px;
			}
		}
		section {
			width:50%;
			float: left;
		}
		svg {
			width:100%;
		}
	}

	@keyframes pop {
		0% {
			transform:scale(1);
		}
		75% {
			transform:scale(1.1);
		}
		100% {
			transform:scale(1);
		}
	}

	.badge {
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height:100%;
		background:rgba(255,255,255,0.7);
		&.ng-hide .badge-panel {
			opacity:0;
			right:-100px;
		}
		&.visible .badge-panel {
			right:0;
			opacity:1;
		}
		&.visible.grabbed {
			.badge-panel {
				transition:all .5s 1s;
				right:0;
				opacity:0;
			}
			button {
				&:after {
					content:"";
					background:url(../images/checkmark@2x.png) center center no-repeat;
					background-size:auto 90%;
					height:103%;
					width:103%;
					position: absolute;
					top:50%;
					left:50%;
					transform:translate(-50%, -50%);
				}
			}
		}
		&.visible .badge-panel {
			p:first-child {
				animation: .5s pop 1s;
			}
			p:nth-child(2){
				animation: .5s pop 1.5s;
			}
			img {
				animation: .67s pop 2s 2;
			}
		}
	}

	.badge-panel {
		background:#0c9dd9;
		position: absolute;
		top:50%;
		transform:translateY(-50%);
		width:30%;
		max-width:25vw;
		height:90%;
		border-top-left-radius: 40px;
		border-bottom-left-radius: 40px;
		display:table;
		text-align: center;
		color:white;
		text-transform: uppercase;
		padding:0 20px;
		font-weight: bold;
		transition:all .5s;

		div {
			display: table-cell;
			vertical-align: middle;
		}

		p {
			@extend %streamHeading;
			font-size:2.5vw;
			margin-bottom:1em;
		}

		svg {
			width:40%;
			display: block;
			margin:0 auto;
			margin-bottom:2em;
		}

		button {
			width:80%;
			display: block;
			margin:0 auto;
			cursor: pointer;
			background:none;
			border:none;
			position: relative;
		}

		img {
			display: block;
		}
	}

}
