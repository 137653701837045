.content {
	&.activity {
		.iframe-container {
			width:100%;
			padding-top:61.5234375%;
			position: relative;
		}
	}
	&.article {
		position: relative;
		.iframe-container {
			width:100%;
			height:100%;
			position: absolute;
			top:0;
			left:0;
		}
	}
	iframe {
		position: absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
	}
	&.photo {
		position: relative;
		height:calc(100vh - 100px);
		background:#333333;
		figure {
			height:100%;
			width:100%;
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
		}
		figure > img {
			width:100%;
			height:100%;
			max-width:100%;
			max-height:100%;
			position: absolute;
			top:0;
			left:0;
			object-fit:contain;
		}
		figcaption {
			position: absolute;
			padding:$verticalSpacing*2 $verticalSpacing $verticalSpacing;
			bottom:0;
			left:0;
			width:100%;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.8+67 */
			background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); /* IE6-9 */
			img {
				display: inline-block;
				vertical-align: middle;
				margin-right:30px;
				width:100px;
			}
			p {
				font-size:21px;
				display: inline-block;
				max-width:600px;
				vertical-align: middle;
				color:white;
				font-weight: bold;
				line-height:1.3;
			}
		}
	}
	&.video {
		max-height:calc(100vh - 100px); //prevents oversizing which could hide the player controls
		min-height:0;
		#field-trips-video {
			max-height:calc(100vh - 200px); //prevents oversizing which could hide the player controls
		}
		&:after {
			display: block;
			content:"";
			background:$blue url(../images/icons/video_icon.png) $horizontalGutter center no-repeat;
			background-size:auto 80%;
			height:$appMenuHeight;
		}
		video {
			width:100%;
			display: block;
		}
	}
}

//hides the photo from IE, which doesn't support object-fit; the background image fallback will still be visible
_:-ms-fullscreen, :root .content.photo figure > img {
	display: none;
}


//JW player overrides
.jw-display-icon-container .jw-button-color:hover, .jw-display-icon-container .jw-button-color:focus {
	color:#FAA819 !important;
}

.jw-button-color.jw-toggle:not(.jw-icon-cast), .jw-button-color:hover:not(.jw-icon-cast), .jw-button-color:focus:not(.jw-icon-cast), .jw-button-color.jw-toggle.jw-off:hover:not(.jw-icon-cast) {
	color:#FAA819 !important;
}

.jw-option.jw-active-option, .jw-option:not(.jw-active-option):hover, .jw-option:not(.jw-active-option):focus, .jw-settings-content-item:hover, .jw-nextup-tooltip:hover, .jw-nextup-tooltip:focus, .jw-nextup-close:hover {
	color:#FAA819 !important;
}

//remove JW branding
.jw-logo-button, .jw-rightclick-link {
	display: none !important;
}